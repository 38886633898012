import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  families: [],
};

export const setFamilies = createAction('SET_FAMILIES');
export const setFamiliesById = createAction('SET_FAMILIES_BY_ID');
export const addFamily = createAction('ADD_FAMILY');
export const updateFamily = createAction('UPDATE_FAMILY');
export const deleteFamily = createAction('DELETE_FAMILY');
export const addRoleFamily = createAction('ADD_ROLE_FAMILY');
export const updateRoleFamily = createAction('UPDATE_ROLE_FAMILY');
export const deleteRoleFamily = createAction('DELETE_ROLE_FAMILY');

export default createReducer(INITIAL_STATE, {
  [setFamilies.type]: (state, action) => ({
    ...state,
    families: action.payload,
  }),
  [setFamiliesById.type]: (state, action) => ({
    ...state,
    families: action.payload,
  }),
  [addFamily.type]: (state, action) => ({
    ...state,
    name: action.payload.name,
    description: action.payload.description,
    skills: action.payload.skills,
    competence: action.payload.competence,
    hierarchy: action.payload.hierarchy,
  }),
  [updateFamily.type]: (state, action) => ({
    ...state,
    name: action.payload.name,
    description: action.payload.description,
    skills: action.payload.skills,
    roles: action.payload.roles || [],
    competence: action.payload.competence,
    hierarchy: action.payload.hierarchy,
  }),
  [deleteFamily.type]: (state, action) => ({
    ...state,
    payload: action.payload,
  }),
  [addRoleFamily.type]: (state, action) => ({
    ...state,
    name: action.payload.name,
    description: action.payload.description,
    hierarchy: action.payload.hierarchy,
    competence: action.payload.competence,
  }),
  [updateRoleFamily.type]: (state, action) => ({
    ...state,
    name: action.payload.name,
    description: action.payload.description,
    hierarchy: action.payload.hierarchy,
    competence: action.payload.competence,
  }),
  [deleteRoleFamily.type]: (state, action) => ({
    ...state,
    payload: action.payload,
  }),
});

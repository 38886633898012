import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

export default function SkillPower({ handleChange, name }) {
  const { Option } = Select;
  return (
    <Form.Item
      label="Potência"
      name={name}
      rules={[
        {
          required: true,
          message: 'Selecione uma potência.',
        },
      ]}
    >
      <Select
        data-testid="select-skill-power"
        placeholder="Selecione a potência"
        onChange={handleChange}
      >
        <Option data-testid="select-option-or" value="Iniciante">
          Iniciante
        </Option>
        <Option data-testid="select-option-or" value="Em Desenvolvimento">
          Em Desenvolvimento
        </Option>
        <Option data-testid="select-option-or" value="Consolidada">
          Consolidada
        </Option>
        <Option data-testid="select-option-or" value="Alta Performance">
          Alta Performance
        </Option>
        <Option data-testid="select-option-or" value="Referência">
          Referência
        </Option>
      </Select>
    </Form.Item>
  );
}

SkillPower.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Card, Skeleton, Avatar, Row, Col, Badge } from 'antd';
import PropTypes from 'prop-types';
import { TeamOutlined, PictureOutlined } from '@ant-design/icons';
import { Button } from '~/components';
import { colors } from '~/constants';
import './styles.scss';

const { Meta } = Card;

export default function GenericCard({
  prefix,
  item,
  handleDelete,
  imgBackup,
  showMembers,
  disabledEdit,
  disabledDelete,
  circleImage,
  displayPhoto,
  customActions,
  additionalActions,
  backgroundColor,
}) {
  const cardActions = [
    <Button
      type="view"
      link={`/${prefix}/details/${item.districtId || item._id}`}
    />,
    <Button
      type="edit"
      link={`/${prefix}/edit/${item.districtId || item._id}`}
      disabled={disabledEdit}
    />,
    <Button
      type="delete"
      id={item.districtId || item._id}
      handleDelete={handleDelete}
      title={item.name}
      disabled={!item._id || disabledDelete}
    />,
  ];

  const [thumbLoading, setThumbLoading] = useState(false);
  const [getColor, setGetColor] = useState('');
  useEffect(() => {
    setThumbLoading(!item.thumb);
    if (
      !Object.values(colors.COLOR_PICKER_OPTIONS).includes(
        backgroundColor.toUpperCase(),
      )
    ) {
      setGetColor(colors.DEFAULT_COLOR);
    } else {
      setGetColor(backgroundColor);
    }
  }, [item.thumb, backgroundColor]);

  const mountAvatar = () => {
    return thumbLoading ? (
      <Skeleton.Avatar
        loading={thumbLoading}
        avatar
        active={thumbLoading}
        size={64}
      />
    ) : item?.thumb?.length > 30 ? (
      circleImage ? (
        <Avatar
          className="tower__avatar"
          size={64}
          alt="ImagemTorre"
          src={item?.thumb}
        />
      ) : (
        <img style={{ width: 50 }} alt="ImagemDistrito" src={item.thumb} />
      )
    ) : (
      <Avatar icon={imgBackup} size={64} />
    );
  };

  return (
    <>
      <Card
        className="generic-card"
        id="generic-card"
        actions={
          customActions
            ? customActions(item)
            : [...cardActions, ...additionalActions]
        }
      >
        <Meta
          className="generic-card__meta"
          avatar={displayPhoto ? mountAvatar() : <></>}
          style={{
            background: getColor,
          }}
          title={
            <div className="generic-card__div">
              <p className="generic-card__title">{item?.name}</p>
              {showMembers && (
                <div className="generic-card__members">
                  <p className="generic-card__members-number">
                    {item?.totalMembers || item?.totalPeople}
                  </p>
                  <TeamOutlined style={{ marginLeft: 2, marginTop: 5 }} />
                </div>
              )}
            </div>
          }
        />

        <div
          style={{
            marginBottom: '50px',
            marginTop: 5,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Row>
            <p
              className="generic-card__description"
              style={{ marginBottom: 20 }}
            >
              {item?.description}
            </p>
          </Row>
        </div>
      </Card>
    </>
  );
}

GenericCard.propTypes = {
  prefix: PropTypes.string,
  item: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  imgBackup: PropTypes.element,
  showMembers: PropTypes.bool,
  disabledEdit: PropTypes.bool,
  disabledDelete: PropTypes.bool,
  circleImage: PropTypes.bool,
  displayPhoto: PropTypes.bool,
  customActions: PropTypes.func,
  additionalActions: PropTypes.array,
  backgroundColor: PropTypes.string,
};

GenericCard.defaultProps = {
  prefix: 'towers',
  imgBackup: <PictureOutlined />,
  showMembers: true,
  disabledEdit: false,
  disabledDelete: false,
  circleImage: false,
  displayPhoto: true,
  customActions: null,
  additionalActions: [],
  backgroundColor: '#1976d2',
};

import React, { useCallback, useEffect } from 'react';
import { Button, Form, Input, Modal, Select, Tabs, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import TextArea from 'antd/lib/input/TextArea';
import { SelectSkills } from '~/components';
import {
  addFamilies,
  updateFamilies,
} from '~/store/fetchActions/fetchFamilies';
import RoleTabInput from './RoleTabInput';

const { Option } = Select;
const { TabPane } = Tabs;

export default function EditBusinessUnitFamily({
  isVisible,
  setVisible,
  family,
  isAddFamily,
  businessUnitId,
  isSubFamilyEdit,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { buId } = useParams();

  const humanityDescriptionArray = [
    'Nosso foco será sempre no impacto positivo que nossas ações e trabalhos geram.',
    'Possui autonomia na gestão do seu trabalho. Sabe o que deve fazer e não precisa estar sendo cobrado.',
    'Demonstra vontade de aprender e crescer, encarando novas situações como oportunidades de aprendizado.',
  ];

  const managementDescriptionArray = [
    'Suporte do fluxo de valor e melhorias do negócio.',
    'Conjunto de valores e práticas que enfatizam a flexibilidade, a colaboração, a adaptação e a entrega contínua de valor proporcionando aprendizado e adaptações rápidas às mudanças.',
    'Está sempre atualizado sobre as tendências, futuro e seus impactos na sociedade e na sua área de atuação.',
  ];

  const leaderDescriptionArray = [
    'Mantém o equilíbrio emocional em situações adversas.',
    'Demonstra autodisciplina e autodirecionamento sabendo trabalhar em time.',
    'É uma pessoa sempre atenta e em busca de novas oportunidades e ideias dentro da empresa.',
  ];

  const handleCancel = useCallback(async () => {
    setVisible(false);
  }, [setVisible]);

  function setSkills(competenceTitle, competenceName, competenceDescription) {
    return {
      name: competenceTitle,
      description: '',
      skills: competenceName.map((item, index) => {
        return {
          name: item,
          description: competenceDescription[index],
        };
      }),
    };
  }

  useEffect(() => {
    form.resetFields();
    const tecnico = [];
    const humana = [];
    const gestao = [];
    const lideranca = [];
    if (isVisible && isAddFamily) {
      form.setFieldsValue({
        technicalName: [],
        humanityName: ['Essência', 'Atitude', 'Inspiração'],
        managementName: [
          'Conhecimento do Negócio',
          'Cultura Ágil',
          'Visão Inovadora',
        ],
        leaderName: [
          'Inteligência Emocional',
          'Líder de si mesmo',
          'Intraempreendedorismo',
        ],
        humanityDescription: humanityDescriptionArray,
        managementDescription: managementDescriptionArray,
        leaderDescription: leaderDescriptionArray,
      });
    }
    if (isVisible && !isAddFamily) {
      family.competence.forEach(comp => {
        comp.skills?.forEach(skill => {
          switch (comp.name) {
            case 'TÉCNICO':
              tecnico.push({
                name: skill.name,
                description: skill.description,
              });
              break;
            case 'HUMANAS':
              humana.push({
                name: skill.name,
                description: skill.description,
              });
              break;
            case 'GESTÃO':
              gestao.push({
                name: skill.name,
                description: skill.description,
              });
              break;
            case 'LIDERANÇA':
              lideranca.push({
                name: skill.name,
                description: skill.description,
              });
              break;
            default:
              break;
          }
        });
      });
      form.setFieldsValue({
        ...family,
        skills: family.skills || [],
        technicalName: tecnico.map(item => item.name),
        technicalDescription: tecnico.map(item => item.description),
        humanityName: humana.map(item => item.name),
        humanityDescription: humana.map(item => item.description),
        managementName: gestao.map(item => item.name),
        managementDescription: gestao.map(item => item.description),
        leaderName: lideranca.map(item => item.name),
        leaderDescription: lideranca.map(item => item.description),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isVisible, family, isAddFamily]);

  const handleOk = useCallback(async () => {
    const hierarchy = family.families?.length;
    return form
      .validateFields()
      .then(
        ({
          name,
          skills,
          description,
          roles,
          technicalName,
          humanityName,
          managementName,
          leaderName,
          technicalDescription,
          humanityDescription,
          managementDescription,
          leaderDescription,
        }) => {
          const competences = [
            setSkills(
              'TÉCNICO',
              technicalName,
              technicalDescription || ['', '', ''],
            ),
            setSkills('HUMANAS', humanityName, humanityDescription),
            setSkills('GESTÃO', managementName, managementDescription),
            setSkills('LIDERANÇA', leaderName, leaderDescription),
          ];

          if (isAddFamily) {
            dispatch(
              addFamilies(
                name,
                skills,
                businessUnitId,
                family,
                competences,
                description,
                hierarchy,
              ),
            );
          } else {
            const familyId = family?.id;
            dispatch(
              updateFamilies(
                name,
                skills,
                roles?.map(role => role.id) || [],
                buId,
                familyId,
                competences,
                description,
              ),
            );
          }

          setVisible(false);
        },
      );
  }, [form, family, isAddFamily, businessUnitId, setVisible, buId, dispatch]);

  return (
    <Modal
      visible={isVisible}
      title={
        isAddFamily
          ? 'Cadastrar Família'
          : 'Editar Objetivos Específicos da Família'
      }
      okText="Salvar"
      cancelText="Cancelar"
      data-testid="business-unit-edit-family-modal"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          data-testid="edit-bu-save-btn"
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" requiredMark>
        <Form.Item
          label="Nome da Família"
          name="name"
          rules={[
            {
              required: true,
              message: 'Nome da Família é obrigatório.',
              whitespace: true,
            },
          ]}
        >
          <Input
            type="text"
            data-testid="family-name-input"
            key="family-name-input"
            placeholder="Nome da Família"
          />
        </Form.Item>
        {!isAddFamily ? (
          <Form.Item label="Cargos da Família" name="roles" defaultValue={[]}>
            <Select
              mode="multiple"
              label="Cargos da Família"
              key="family-roles-select"
              tagRender={({ label: { id, name } }) => (
                <Tag
                  data-testid={`role-option-${id}`}
                  style={{ margin: 2 }}
                  closable={false}
                >
                  {name}
                </Tag>
              )}
              disabled
            >
              {family?.roles?.map(({ id, name }) => (
                <Option key={`role-option-${id}-${name}`}>{name}</Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <></>
        )}
        <SelectSkills
          required
          tagRenderFunc={({ label, ...props }) => (
            <Tag
              data-testid={`skill-${label}`}
              style={{ margin: 2 }}
              color="blue"
              {...props}
            >
              {label}
            </Tag>
          )}
          fieldLabel="Objetivos Específicos da Família"
          placeholder="Buscar objetivo específico (ex.: Análise de indicadores, React)"
          testId="select-family-skills"
          selectMode="multiple"
          fieldName="skills"
        />
        <Form.Item
          name="description"
          required
          label="Descrição"
          rules={[
            {
              required: true,
              message: 'A descrição é obrigatória.',
              whitespace: true,
            },
          ]}
        >
          <TextArea
            placeholder="Insira uma descrição"
            data-testid="family-description-input"
            autoSize={{ minRows: 4, maxRows: 4 }}
          />
        </Form.Item>
        {!isSubFamilyEdit && (
          <Tabs defaultValue={1}>
            <TabPane tab="Técnica" key={1} forceRender>
              <RoleTabInput
                numberOfInputs={3}
                label="Técnica"
                name="technicalName"
                nameDescription="technicalDescription"
              />
            </TabPane>
            <TabPane tab="Humanas" key={2} forceRender>
              <RoleTabInput
                numberOfInputs={3}
                label="Humanas"
                name="humanityName"
                nameDescription="humanityDescription"
              />
            </TabPane>
            <TabPane tab="Gestão" key={3} forceRender>
              <RoleTabInput
                numberOfInputs={3}
                label="Gestão"
                name="managementName"
                nameDescription="managementDescription"
              />
            </TabPane>
            <TabPane tab="Liderança" key={4} forceRender>
              <RoleTabInput
                numberOfInputs={3}
                label="Liderança"
                name="leaderName"
                nameDescription="leaderDescription"
              />
            </TabPane>
          </Tabs>
        )}
      </Form>
    </Modal>
  );
}

EditBusinessUnitFamily.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  family: PropTypes.objectOf(PropTypes.any),
  isAddFamily: PropTypes.bool,
  businessUnitId: PropTypes.string,
  isSubFamilyEdit: PropTypes.bool,
};

EditBusinessUnitFamily.defaultProps = {
  family: {},
  isAddFamily: true,
  businessUnitId: '',
  isSubFamilyEdit: false,
};

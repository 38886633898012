import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Select, Tabs, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import TextArea from 'antd/lib/input/TextArea';
import { SelectSkills } from '~/components';
import {
  addRoleFamilies,
  addSubFamilies,
  updateRoleFamilies,
} from '~/store/fetchActions/fetchFamilies';
import { selectFamilies } from '~/store/selectors';
import RoleTabInput from './RoleTabInput';

const { Option } = Select;
const { TabPane } = Tabs;

export default function AddRoleOrSubFamily({
  isVisible,
  setVisible,
  familyId,
  isRole,
  role,
  familyList,
  currentFamilyExpanded,
}) {
  const [type, setType] = useState('');
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { buId } = useParams();
  const { families } = useSelector(selectFamilies);
  const [technical, setTechnical] = useState([]);
  const [humanity, setHumanity] = useState([]);
  const [management, setManagement] = useState([]);
  const [leader, setLeader] = useState([]);
  const [skillObjects, setSkillObjects] = useState([]);
  const [nameRole, setNameRole] = useState();
  const [request, setRequest] = useState();
  const [hierarchy, setHierarchy] = useState();
  const [descriptionRole, setDescriptionRole] = useState();
  const disabled = true;
  const isSkillPower = true;

  function getSkills(item, setSkills) {
    setSkills(prevSkill => {
      const previousSkills = Array.isArray(prevSkill) ? prevSkill : [];
      const idExists = previousSkills.some(skill => skill.id === item.id);

      if (!idExists) {
        return { id: item.id, skills: [...previousSkills, ...item.skills] };
      }

      return { skills: [...previousSkills, ...item.skills] };
    });
  }

  useEffect(() => {
    if (currentFamilyExpanded) {
      currentFamilyExpanded?.competence?.forEach(item => {
        switch (item.name) {
          case 'TÉCNICO':
            getSkills(item, setTechnical);
            break;
          case 'HUMANAS':
            getSkills(item, setHumanity);
            break;
          case 'GESTÃO':
            getSkills(item, setManagement);
            break;
          case 'LIDERANÇA':
            getSkills(item, setLeader);
            break;
          default:
            break;
        }
      });
    }
  }, [currentFamilyExpanded]);

  useEffect(() => {
    form.resetFields();
    const tecnico = [];
    const humana = [];
    const gestao = [];
    const lideranca = [];

    if (isVisible) {
      form.setFieldsValue({
        technicalName: technical?.skills?.map(item => item.name),
        humanityName: humanity?.skills?.map(item => item.name),
        managementName: management?.skills?.map(item => item.name),
        leaderName: leader?.skills?.map(item => item.name),
      });
    }

    if (isVisible && role) {
      role.competence.forEach(comp => {
        comp.skills.forEach(skill => {
          if (comp.name === 'TÉCNICO') {
            tecnico.push(skill.power);
          }
          if (comp.name === 'HUMANAS') {
            humana.push(skill.power);
          }
          if (comp.name === 'GESTÃO') {
            gestao.push(skill.power);
          }
          if (comp.name === 'LIDERANÇA') {
            lideranca.push(skill.power);
          }
        });
      });

      form.setFieldsValue({
        ...role,
        selecttechnicalName: tecnico,
        selecthumanityName: humana,
        selectmanagementName: gestao,
        selectleaderName: lideranca,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isVisible, role]);

  const handleCancel = useCallback(() => {
    setVisible(false);
    form.resetFields();
    setType('');
  }, [setVisible, form]);

  const handleChangeType = $event => {
    setType($event);
  };

  const setCompetenceList = (
    selectTechnicalName,
    selectHumanityName,
    selectManagementName,
    selectLeaderName,
    hierarchyProp,
    requestType,
  ) => {
    return new Promise(() => {
      const selectedSkills = [
        {
          idFamilyCompetence: technical.id,
          skills: technical.skills.map((skill, index) => ({
            idFamilySkill: skill.id,
            power: selectTechnicalName ? selectTechnicalName[index] : '',
          })),
        },
        {
          idFamilyCompetence: humanity.id,
          skills: humanity.skills.map((skill, index) => ({
            idFamilySkill: skill.id,
            power: selectHumanityName ? selectHumanityName[index] : '',
          })),
        },
        {
          idFamilyCompetence: management.id,
          skills: management.skills.map((skill, index) => ({
            idFamilySkill: skill.id,
            power: selectManagementName ? selectManagementName[index] : '',
          })),
        },
        {
          idFamilyCompetence: leader.id,
          skills: leader.skills.map((skill, index) => ({
            idFamilySkill: skill.id,
            power: selectLeaderName ? selectLeaderName[index] : '',
          })),
        },
      ];

      setSkillObjects(selectedSkills);
      setRequest(requestType);
      setHierarchy(hierarchyProp);
    });
  };

  useEffect(() => {
    if (role && skillObjects && skillObjects.length > 0) {
      dispatch(
        updateRoleFamilies(
          nameRole,
          role.id,
          buId,
          descriptionRole,
          skillObjects,
        ),
      );
      setSkillObjects([]);
      setVisible(false);
      form.resetFields();
    }

    if (
      request === 'post' &&
      hierarchy &&
      skillObjects &&
      skillObjects.length > 0
    ) {
      dispatch(
        addRoleFamilies(
          nameRole,
          familyId,
          buId,
          descriptionRole,
          skillObjects,
          hierarchy,
        ),
      );
      setSkillObjects([]);
      setVisible(false);
      form.resetFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillObjects, role, nameRole, buId, hierarchy, request, dispatch]);

  const handleOk = useCallback(async () => {
    const {
      name,
      selectRoleSubFamily,
      selecttechnicalName,
      selecthumanityName,
      selectmanagementName,
      selectleaderName,
      description,
      skills,
    } = await form.validateFields();
    setNameRole(name);
    setDescriptionRole(description);
    const hierarchyProp = currentFamilyExpanded.roles.length + 1;

    if (role) {
      try {
        await setCompetenceList(
          selecttechnicalName,
          selecthumanityName,
          selectmanagementName,
          selectleaderName,
        );
      } catch (error) {
        return error;
      }
    }
    if (selectRoleSubFamily === 'role') {
      await setCompetenceList(
        selecttechnicalName,
        selecthumanityName,
        selectmanagementName,
        selectleaderName,
        hierarchyProp,
        'post',
      );
    } else {
      dispatch(addSubFamilies(name, skills, description, familyId, buId));
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, familyId, buId, setVisible, role, familyList, dispatch]);

  return (
    <Modal
      visible={isVisible}
      title={role ? 'Editar Cargo' : 'Adicionar Cargos ou Sub-Famílias'}
      okText="Salvar"
      cancelText="Cancelar"
      data-testid="business-unit-edit-family-modal"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          data-testid="edit-bu-save-btn"
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" requiredMark>
        {!role ? (
          <Form.Item
            label="Tipo"
            name="selectRoleSubFamily"
            rules={[
              {
                required: true,
                message: 'Selecione um tipo.',
                whitespace: true,
              },
            ]}
          >
            <Select
              data-testid="select-role-subFamily"
              placeholder="Selecione o tipo"
              onChange={$event => handleChangeType($event)}
            >
              <Option
                data-testid="select-option-or"
                value="role"
                selected
                disabled={families?.subFamiliesIds?.length > 0}
              >
                Cargos
              </Option>
              {!isRole ? (
                <Option
                  data-testid="select-option-and"
                  value="subFamily"
                  disabled={families?.rolesIds?.length > 0}
                >
                  Sub-Famílias
                </Option>
              ) : (
                <></>
              )}
            </Select>
          </Form.Item>
        ) : (
          <></>
        )}
        {(type || role) && (
          <>
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Nome é obrigatório.',
                  whitespace: true,
                },
              ]}
            >
              <Input
                type="text"
                data-testid="name"
                key="name"
                placeholder="Digite um nome"
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Descrição"
              rules={[
                {
                  required: true,
                  max: 1000,
                  message:
                    'A descrição é obrigatória e deve ter até 1000 caracteres',
                  whitespace: true,
                },
              ]}
            >
              <TextArea
                placeholder="Insira uma descrição"
                data-testid="tower-description-input"
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
          </>
        )}
        {type === 'role' || role ? (
          <>
            <Tabs defaultValue={1}>
              <TabPane tab="Técnica" key={1} forceRender>
                <>
                  <RoleTabInput
                    numberOfInputs={3}
                    label="Técnica"
                    name="technicalName"
                    disabled={disabled}
                    isSkillPower={isSkillPower}
                  />
                </>
              </TabPane>
              <TabPane tab="Humanas" key={2} forceRender>
                <RoleTabInput
                  numberOfInputs={3}
                  label="Humanas"
                  name="humanityName"
                  disabled={disabled}
                  isSkillPower={isSkillPower}
                />
              </TabPane>
              <TabPane tab="Gestão" key={3} forceRender>
                <RoleTabInput
                  numberOfInputs={3}
                  label="Gestão"
                  name="managementName"
                  disabled={disabled}
                  isSkillPower={isSkillPower}
                />
              </TabPane>
              <TabPane tab="Liderança" key={4} forceRender>
                <RoleTabInput
                  numberOfInputs={3}
                  label="Liderança"
                  name="leaderName"
                  disabled={disabled}
                  isSkillPower={isSkillPower}
                />
              </TabPane>
            </Tabs>
          </>
        ) : (
          <></>
        )}
        {type === 'subFamily' ? (
          <SelectSkills
            tagRenderFunc={({ label, ...props }) => (
              <Tag
                data-testid={`skill-${label}`}
                style={{ margin: 2 }}
                color="blue"
                {...props}
              >
                {label}
              </Tag>
            )}
            fieldLabel="Objetivos Específicos da Sub-Família"
            placeholder="Buscar objetivo específico (ex.: Análise de indicadores, React)"
            testId="select-family-skills"
            selectMode="multiple"
            fieldName="skills"
          />
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
}

AddRoleOrSubFamily.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  familyId: PropTypes.string,
  currentFamilyExpanded: PropTypes.object,
  isRole: PropTypes.bool,
  role: PropTypes.objectOf(PropTypes.any),
  familyList: PropTypes.objectOf(PropTypes.any),
};

AddRoleOrSubFamily.defaultProps = {
  familyId: '',
  currentFamilyExpanded: {},
  isRole: true,
  role: {},
  familyList: {},
};

import { Card, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import SkillPower from './SkillPower';
import TechnicalDescription from './TechnicalDescription';

export default function RoleTabInput({
  numberOfInputs,
  label,
  name,
  nameDescription,
  placeholder,
  handleChange,
  disabled,
  isSkillPower,
}) {
  return (
    <Card>
      {[...Array(numberOfInputs)].map((_, index) => (
        <>
          <Form.Item
            label={`${label} ${index + 1}`}
            name={[`${name}`, index]}
            rules={[
              {
                required: true,
                message: `${label} ${index + 1} é obrigatório.`,
                whitespace: true,
              },
            ]}
          >
            <Input
              type="text"
              data-testid={`${name}-${index}`}
              placeholder={placeholder}
              disabled={disabled}
            />
          </Form.Item>
          {!isSkillPower && (
            <TechnicalDescription name={[`${nameDescription}`, index]} />
          )}
          {isSkillPower && (
            <div style={{ width: '50%' }}>
              <SkillPower
                name={[`select${name}`, index]}
                handleChange={handleChange}
              />
            </div>
          )}
        </>
      ))}
    </Card>
  );
}

RoleTabInput.propTypes = {
  numberOfInputs: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameDescription: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  isSkillPower: PropTypes.bool,
};

RoleTabInput.defaultProps = {
  placeholder: 'Digite um nome',
  handleChange: () => {},
  disabled: false,
  isSkillPower: false,
};

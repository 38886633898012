/* eslint-disable no-console */
import { message } from 'antd';
import api from '~/config/httpRequest';
import {
  setPractices,
  setCostCenters,
  setCostCentersNoBu,
  setCostCentersNoGroup,
} from '~/store/ducks/practices';
import { validationMessages } from '~/constants';
import { startLoading, endLoading } from '~/store/ducks/loading';

export function getPractices(shouldLoadMembers = true) {
  return async dispatch => {
    dispatch(startLoading('isLoadingPractices'));
    api
      .get(`/soluters/journey/practices`)
      .then(({ data: payload }) => {
        dispatch(setPractices(payload));
        // Observar se irá quebrar em outro canto do site
        // if (payload) {
        //   Promise.all(
        //     payload?.map(async practice => {
        //       try {
        //         practice = {
        //           ...practice,
        //           totalMembers: shouldLoadMembers
        //             ? await getPracticeMembers(practice.id)
        //             : 0,
        //         };
        //       } catch (error) {
        //         console.log(error);
        //       }
        //       return practice;
        //     }),
        //   ).then(practices => dispatch(setPractices(practices)));
        // }
        dispatch(endLoading('isLoadingPractices'));
      })
      .catch(err => {
        console.log(err);
        dispatch(setPractices(null));
        dispatch(endLoading('isLoadingPractices'));
      });
  };
}

export function addPractice(practice) {
  return async dispatch => {
    api
      .post(`/soluters/journey/practices`, practice)
      .then(res => {
        dispatch(getPractices());
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function editPractice(practice, practiceId) {
  return async dispatch => {
    dispatch(startLoading('isLoadingPractices'));
    api
      .put(`/soluters/journey/practices/${practiceId}`, practice)
      .then(res => {
        dispatch(getPractices());
        dispatch(getCostCentersNoBus());
        message.success(validationMessages.HTTP_EDIT_SUCCESS_MESSAGE);
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function deletePractice(practiceId) {
  return async dispatch => {
    api
      .delete(`/soluters/journey/practices/${practiceId}`)
      .then(({ data: payload }) => {
        dispatch(getPractices());
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export const getCostCenters = searchTerm => {
  const search = /^[0-9]+$/.test(searchTerm)
    ? `termId=${searchTerm}`
    : `term=${searchTerm}`;
  return async dispatch => {
    dispatch(startLoading('isLoadingCostCenter'));
    api
      .get(`/soluters/journey/teams?${search}`)
      .then(({ data: payload }) => {
        dispatch(setCostCenters(payload));
        dispatch(endLoading('isLoadingCostCenter'));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingCostCenter'));
      });
  };
};

export const getCostCentersNoBus = () => dispatch => {
  api
    .get('/soluters/journey/teams/no-practice')
    .then(({ data }) => {
      dispatch(setCostCentersNoBu(data));
    })
    .catch(() => {
      dispatch(setCostCentersNoBu([]));
    });
};

export const getCostCentersNoGroup = () => dispatch => {
  dispatch(startLoading('isLoadingTower'));
  api
    .get('/soluters/journey/teams/no-group')
    .then(({ data }) => {
      dispatch(setCostCentersNoGroup(data));
      dispatch(endLoading('isLoadingTower'));
    })
    .catch(() => {
      dispatch(setCostCentersNoGroup([]));
      dispatch(endLoading('isLoadingTower'));
    });
};

export const getPracticeMembers = practiceId => {
  return new Promise((resolve, reject) => {
    api
      .get(`/soluters?term=`, {
        params: {
          practiceId,
        },
      })
      .then(response => resolve(response?.data?.total || 0))
      .catch(erro => reject(erro));
  });
};

import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PropTypes from 'prop-types';

export default function TechnicalDescription({ name }) {
  return (
    <Form.Item name={name} label="Descrição">
      <TextArea
        placeholder="Insira uma descrição"
        data-testid="technical-description-input"
        autoSize={{ minRows: 4, maxRows: 4 }}
      />
    </Form.Item>
  );
}

TechnicalDescription.propTypes = {
  name: PropTypes.string.isRequired,
};

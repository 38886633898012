import {
  initializeFaro,
  createReactRouterV5Options,
  ReactIntegration,
  getWebInstrumentations,
} from '@grafana/faro-react';
import { createBrowserHistory } from 'history';
import { Route } from 'react-router-dom';

const history = createBrowserHistory();
const enableFaro = process.env.REACT_APP_FARO_ENABLED === 'true';
let initFaro = null;

const urlGrafanaInstrumentation =
  process.env.REACT_APP_FARO_URL != null
    ? process.env.REACT_APP_FARO_URL
    : 'https://alloy.internal.solutis.xyz/collect';

if (enableFaro) {
  initFaro = {
    isolate: true,
    url: urlGrafanaInstrumentation,
    app: {
      name: 'passaport-back-office',
    },
    version: '1.0.0',
    instrumentations: [
      ...getWebInstrumentations({
        captureConsole: true,
        captureConsoleDisabledLevels: [],
        enablePerformanceInstrumentation: true,
      }),
      new ReactIntegration({
        router: createReactRouterV5Options({
          history,
          Route,
        }),
      }),
    ],
  };
}

export const initializeTelemetry = () => {
  const faro = initFaro != null ? initializeFaro(initFaro) : null;
  faro?.api.pushLog(['Faro was initialized']);
  return faro;
};
